<template>
    <div class="home">
        <Header></Header>
        <main class="main">
            <section class="section__terms">
                <div class="section__terms--title">
                  <h1>TERMS</h1>
                  <h3>貸渡約款</h3>
                </div>
                <div class="section__terms--content">
                    <div class="terms__chapter">
                        <h2 class="terms__chapter--title">第1章 総則</h2>
                        <div class="terms__chapter--paragraph">
                            <h3 class="terms__chapter--paragraph-title">第1条 (約款の適応)</h3>
                            <ol class="terms__chapter--paragraph-content">
                                <li>
                                    当社はこの約款(以下「約款」という)及び細則の定めるところにより、貸渡自動車(以下「レンタカー」という)を借受人に貸渡すものとし、借受人はこれを借受けるものとしますなお、約款及び細則に定めのない事項については、法令又は一般の習によるものとします。
                                </li>
                                <li>
                                    当社は、約款及び細則の趣旨法令及び一般の慣習に反しない範囲で特約に応ずることがあります。特約した場合には、その特約が約款及び細則に優先するものとします。
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div class="terms__chapter">
                        <h2 class="terms__chapter--title">第2章 予約</h2>
                        <div class="terms__chapter--paragraph">
                            <h3 class="terms__chapter--paragraph-title">第2条 (予約の申込)</h3>
                            <ol class="terms__chapter--paragraph-content">
                                <li>
                                    借受人は、レンタカーを借受けるにあたって、約款及び当社所定の料金表等に同意のうえ、当社所定の方法により、予め車種クラス、借受開始日時、借受場所、借受期返還場所運転者、チャイルドシート等付属品の要否、その他の借受条件(以下「借受条件」という)を明示して予約の申込を行うことがてきます。
                                </li>
                                <li>
                                    当社は、借受人から予約の申込があったときは、原則として、当社の保有するレンタカーの範囲内で予約に応ずるものとします。この場合、借受人は、当社が特に認める場合を除き、当社所定の予約申込金を支払うものとします。
                                </li>
                            </ol>
                            <h3 class="terms__chapter--paragraph-title">第3条 (予約の変更)</h3>
                            <p class="terms__chapter--paragraph-content">
                                借受人は、受条件を変更しようとするときは、あらかじめ当社の承諾を受けなければならないものとします。      
                            </p>
                            <h3 class="terms__chapter--paragraph-title">第4条 (予約の取消等)</h3>
                            <ol class="terms__chapter--paragraph-content">
                                <li>
                                    借受人及び当社は、当社所定の方法により、予約を取消すことができます。なお、
                                    借受人か、借受人の都合により、予約した借受開始時刻を1時間以上経過してもレンタカー貸渡契約(以下「貸渡契約」という)の締結手続きに着手しなかったときは、事情の如何を問わず、予約が取消されたものとします。
                                </li>
                                <li>
                                    借受人の都合により予約が取消されたときは、借受人は別に定めるところにより当社所定の予約取消手数料を当社に支払うものとし、当社は、この予約取消手数料の支払があったときは、受領済の予約申込金を借受人に返還するものとします。
                                </li>
                                <li>
                                    当社の都合により予約が取消されたときは、当社は、受領済の予約申込金を借受人に返還するほか当社所定の違約金を支払うものとします。
                                </li>
                                <li>
                                    前2項以外の事由により貸渡契約が締結されなかったときは、予約は取消されたものとします。この場合、当社は受領済の予約申込金を受人に返還するものとします。
                                </li>
                                <li>
                                    事故、盗難、不返還、リコール、大災その他の借受人若しくは当社のいずれの責にもよらない事由により貸渡契約が締結されなかったときは、予約は取り消されたものとします。この場合、当社は受領済の予約申込金を返還するものとします。
                                </li>
                            </ol>
                            <h3 class="terms__chapter--paragraph-title">第5条 (代替レンタカー)</h3>
                            <ol class="terms__chapter--paragraph-content">
                                <li>
                                    当社は、借受人から予約のあった車種クラスのレンタカーを貸し渡すことができな
                                    いときは、借受人に対し、予約と異なる車種クラスのレンタカー(以下「代替レン
                                    タカー」といいます。)の貸渡しを申し入れることかできるものとします。
                                </li>
                                <li>
                                    借受人が前項の申入れを承諾したときは、当社は車種クラスを除き予約時と同一の
                                    借受条件で代替レンタカーを貸し渡すものとしますなお、代替レンタカの貸渡
                                    料金が予約された車種クラスの貸渡料金より高くなるときは、予約した車種クラス
                                    の貸渡料金によるものとし、予約された車種クラスの貸渡料金より低くなるときは、
                                    当該代替レンタカーの車種クラスの貸渡料金によるものとします。
                                </li>
                                <li>
                                    借受人は、第1項の代替レンタカーの貸渡しの申入れを拒絶し、予約を取り消すことができるものとします。
                                </li>
                                <li>
                                    前項の場合において、貸渡すことができない原因が、当社の責に帰すべき事由によ
                                    るときには第4条第3項の予約の取消しに準じて取り扱い、当社は受領済の予約申
                                    込金を返還するものとします。
                                </li>
                                <li>
                                    第3項の場合において、第1項の貸し渡すことができない原因が、事故、盗難、不
                                    返還、リコール等の事由、他の借受人によるレンタカーの返却遅延又は天災その他
                                    当社の責に帰さない事由によるときには第4条第5項の予約の取消しに準じて取り
                                    扱い、当社は受領済の予約申込金を返還するものとします。
                                </li>
                            </ol>
                            <h3 class="terms__chapter--paragraph-title">第6条 (免責)</h3>
                            <ol class="terms__chapter--paragraph-content">
                                <li>
                                    当社及び借受人は、予約が取り消され又は貸渡契約か締結されなかったことについては、第4条及び第5条に定める場合を除き、相互に何らの請求をしないものとします。
                                </li>
                            </ol>
                            <h3 class="terms__chapter--paragraph-title">第7条 (予約業務の代行)</h3>
                            <ol class="terms__chapter--paragraph-content">
                                <li>
                                    借受人は、当社に代わって予約業務を取扱う旅行代理店・提携会社等(以下「代行業者」という)において予約の申込をすることができます。
                                </li>
                                <li>
                                    前項の申込を行ったときは、借受人は予約の変更又は取消をその申込を行った代行業者に対してするものとします。
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div class="terms__chapter">
                        <h2 class="terms__chapter--title">第3章 貸渡</h2>
                        <div class="terms__chapter--paragraph">
                            <h3 class="terms__chapter--paragraph-title">第8条 (貸渡契約の締結)</h3>
                            <ol class="terms__chapter--paragraph-content">
                                <li>
                                    借受人は借受条件を、当社は約款・料金表等により貸渡条件を、それぞれ明示して、貸渡契約を締結するものとします。
                                </li>
                                <li>
                                    運転者は、貸渡契約の締結にあたり、約款及び細則で運転者の義務と定められた事項を遵守するものとします。
                                </li>
                                <li>
                                    当社は、監督官庁による基本通達に基づき、貸渡簿(貸渡原票)及び第14条に規定する貸渡証に運転者の氏名・住所運転免許の種類及び運転免許証の番号を記載し又は運転者の運転免許の写しを添付するため、貸渡契約の締結にあたり、借受人に対し、借受人の指定する運転者の運転免許証の提示を求め、当社が必要と認めた場合まその写しの提出を求めます。
                                    この場合、借受人は自己が運転者であるときは自己の運転免許証を提示し、当社が求めた場合はその写しを提出するものとし、借受人と運転者が異なるときは運転者をしてその運転者の運転免許証を提示させ、当社が求めた場合はその写しを提出させるものとします。
                                </li>
                                <li>
                                    当社は、貸渡契約の締結にあたり借受人に対し、運転免許証の他に身元を証明する書類の提出を求め、提出された書類の写しをとることがあります。
                                </li>
                                <li>
                                    当社は、貸渡契約の締結にあたり、借受人又は運転者に携帯電話番号等の緊急連絡先の提示を求めるものとします。
                                </li>
                                <li>
                                    当社は、貸渡契約の締結にあたり、借受人に対し、クレジットカード・現金等の支払方法を指定することがあります。
                                </li>
                                <li>
                                    当社は、借受人又は運転者が前5項に従わない場合は貸渡契約の締結を拒絶するとともに、予約を取消すことができるものとします。なお、この場合の予約申込金等の扱いについては、第4粂第5項を適用するものとします。
                                </li>
                            </ol>
                            <h3 class="terms__chapter--paragraph-title">第9条 (貸渡拒絶)</h3>
                            <ol class="terms__chapter--paragraph-content">
                                <li>
                                    当社は、借受人又は運転者が次の各号に該当する場合には、貸渡契約の締結を拒絶するとともに、予約を取り消すことができるものとします。
                                    <ol>
                                        <li>
                                            レンタカーの運転に必要な運転免許至を有していないとき、又は当社に対して当該運転免許証の提示若しくはその写しの提出をしないとき。
                                        </li>
                                        <li>
                                            酒気を帯びていると認められるとき。
                                        </li>
                                        <li>
                                            麻薬、覚せい剤、シンナー等による中毒症状等を呈していると認められるとき。
                                        </li>
                                        <li>
                                            チャイルドシートがないにもかかわらず、6才未満の幼児を同乗させるとき。
                                        </li>
                                        <li>
                                            指定暴力団、指定暴力団関係団体の構成員又は関係者、その他反社会的組織に属していると認められるとき。
                                        </li>
                                        <li>
                                            予約に際して定めた運転者と貸渡契約締結時の運転者とが異なるとき。
                                        </li>
                                        <li>
                                            過去の貸渡しにおいて、貸渡料金の支払いを滞納した事実があるとき。
                                        </li>
                                        <li>
                                            過去の貸渡しにおいて、第17条各号に掲げる行為があったとき。
                                        </li>
                                        <li>
                                            過去の貸渡し(他のレンタカー事業者による貸渡しを含みます。)において第18条第7項又は第24条第1項に掲げる行為があったとき。
                                        </li>
                                        <li>
                                            過去の貸渡しにおいて、貸渡約款又は保険約款違反により自動車保険か適用されなかった事実があったとき。
                                        </li>
                                        <li>
                                            別に明示する条件を満たしていないとき。
                                        </li>
                                        <li>
                                            当社との取引に関し、当社の従業員その他の関係者に対して暴力的行為を行い、若しくは合理的範囲を超える負担を要求し、又は暴力的行為若しくは言辞を用いたとき。
                                        </li>
                                        <li>
                                            風説を流布し、又は偽計若しくは威力を用いて当社の信用をき損し、又は業務を妨害したとき。
                                        </li>
                                        <li>
                                            約款及ひ細則に違反する行為があったとき。
                                        </li>
                                        <li>
                                            その他、当社が不適当と認めたとき。
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    前項にかかわらず次の各号の場合にも、当社は貸渡契約の締結を拒絶するとともに、予約を取消すことがてきるものとします。
                                    <ol>
                                        <li>
                                            貸渡してきるレンタカーがないとき。
                                        </li>
                                        <li>
                                            借受人又は運転者が6才未満の幼児を同乗させるにもかかわらずチャイルドシートがないとき。
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    前2項に基づき当社が貸渡契約の締結を拒絶した場合の予約申込金等の扱いについては、第4条第3項~第6項を適用するものとします。
                                </li>
                            </ol>
                            <h3 class="terms__chapter--paragraph-title">第10条 (貸渡契約の成立等)</h3>
                            <ol class="terms__chapter--paragraph-content">
                                <li>
                                    貸渡契約は、借受人が貸渡契約書に署名をし、当社が借受人にレンタカー(付属品を含む。以下同じ)を引渡したときに成立するものとします。この場合、受領済の予約申込金は貸渡料金の一部に充当されるものとします。
                                </li>
                                <li>
                                    前項の引渡は、第2条の借受開始日時及び借受場所で行うものとします。
                                </li>
                            </ol>
                            <h3 class="terms__chapter--paragraph-title">第11条 (貸渡料金)</h3>
                            <ol class="terms__chapter--paragraph-content">
                                <li>
                                    貸渡契約が成立した場合、借受人は当社に対して項に定める貸渡料金を支払うものとします。
                                </li>
                                <li>
                                    貸渡料金とは、以下の合計金額をいうもの とし、 当社はそれぞれの金額又はその照会先を料金表に明示します。
                                    <ol>
                                        <li>基本料金</li>
                                        <li>免責補償料</li>
                                        <li>特別装備料</li>
                                        <li>ワンウェイ料金</li>
                                        <li>燃料代</li>
                                        <li>引取配車料</li>
                                        <li>その他の料金</li>
                                    </ol>
                                </li>
                                <li>
                                    基本料金は、レンタカーの貸渡時において、各地方運輸局運輸支局長、神戸運輸監理部兵庫陸運部長又は沖縄総合事務局陸運事務所長に届け出て実施 している料金によるものとします。
                                </li>
                                <li>
                                    当社が、貸渡料金を、第2条による予約を完了した後に改定したときは、借受人は予約完丁時に適用した料金と貸渡時の料金のうち、いずれか低い方の料金を支払うものとします。
                                </li>
                            </ol>
                            <h3 class="terms__chapter--paragraph-title">第12条 (借受条件の変更)</h3>
                            <ol class="terms__chapter--paragraph-content">
                                <li>
                                    借受人は、貸渡契約の締結後、第8条の借受条件を変更しようとするときは、あらかじめ当社の承諾を受けなければならないものとします。
                                </li>
                                <li>
                                    当社は、前項による借受条件の変更によって貸渡業務に支障が生ずるときは、その 変更を承諾しないことがあります。
                                </li>
                            </ol>
                            <h3 class="terms__chapter--paragraph-title">第13条 (点検整備等)</h3>
                            <ol class="terms__chapter--paragraph-content">
                                <li>
                                    当社は、道路運送車両法第 47 条の2 (日常点検整備)及び第 48 条(定期点検整備)に定める点検をし、必要な整備を実施したレンタカーを貸渡すものとします。
                                </li>
                                <li>
                                    借受人又は運転者は 、レンタカーの貸渡にあたり、別に定める点検表に基づく車体外観及び付属品の検査を行い、レンタカーに整備不良がないこと等を確認するとともに、レンタカーが借受条件を満たしていることを確認するものとします。
                                </li>
                                <li>
                                    当社は、前項の確認によってレンタカーに整備不良が発見された場合には、直ちに必要な整備等を実施するものとします。
                                </li>
                            </ol>
                            <h3 class="terms__chapter--paragraph-title">第14条 (貸渡証の交付・携行等)</h3>
                            <ol class="terms__chapter--paragraph-content">
                                <li>
                                    当社は、レンタカーを引したときは、各地方運輸局運輸支局長、神戸輸監理部兵庫陸運部長又は沖縄総合事務局陸運事務所長が定めた内容を記載した所定の貸渡証を借受人に交付するものとします。
                                </li>
                                <li>
                                    借受人又は運転者は、レンタカーの使用中、前項により交付を受けた貸渡証を携行しなければならないものとします。
                                </li>
                                <li>
                                    借受人又は運転者は、貸渡証を紛失したときは、直ちにその旨を当社に通知するものとします。
                                </li>
                                <li>
                                    借受人又は運転者は、レンタカーの返還とともに、貸渡証を当社に返還するものとします。
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div class="terms__chapter">
                        <h2 class="terms__chapter--title">第4章 使用</h2>
                        <div class="terms__chapter--paragraph">
                            <h3 class="terms__chapter--paragraph-title">第15条 (借受人の管理責任)</h3>
                            <ol class="terms__chapter--paragraph-content">
                                <li>
                                    借受人又は運転者は、レンタカーの引渡を受けてから当社に返還するまでの間(以下「使用中」という)、善良な管理者の注意をもってレンタカーを使用し、保管するものとします。
                                </li>
                                <li>
                                    借受人又は運転者は、レンタカーを使用する際には、法令、約款、細則、取扱説明書、その他当社が提示する使用法を遵守しレンタカーを使用するものとします。
                                </li>
                            </ol>
                            <h3 class="terms__chapter--paragraph-title">第16条 (日常点検整備)</h3>
                            <p class="terms__chapter--paragraph-content">
                                借受人又は運転者は、使用中、借受けたレンタカーについて、毎日使用する前に道路運送車両法第47条の2(日常点検整備)に定める日常点検整備を実施しなければならないものとします。
                            </p>
                            <h3 class="terms__chapter--paragraph-title">第17条 (禁止行為)</h3>
                            <ol class="terms__chapter--paragraph-content">
                                <li>
                                    借受人又は運転者は、使用中に次の行為をしてはならない ものとします。
                                    <ol>
                                        <li>
                                            当社の承諾及び道路運送法に基づく許可等を受けることなくレンタカーを自動車運送事業又はこれに類する目的に使用すること。
                                        </li>
                                        <li>
                                            動車運送事業又はこれに類する目的に使用すること。レンタカーを所定の使用目的以外に使用し又は第8条の運転者以外の者に運転させること。
                                        </li>
                                        <li>
                                            レンタカーを転貸し、第三者に使用させ又は他に担保の用に供する等、当社の権利を侵害することとなる一切の行為をすること。
                                        </li>
                                        <li>
                                            レンタカーの自動車登録番号標又は車両番号標を造若しくは変造し、又はレンタカーを改造若しくは改装する等その原状を変更すること。
                                        </li>
                                        <li>
                                            当社の承諾を受けることなく、レンタカーを各種テスト若しくは競技(当社が競技に該当すると判断するものを含む)に使用し又は他車の牽引若しくは後押しに使用すること。
                                        </li>
                                        <li>
                                            法令又は公序良俗に違反してレンタカーを使用すること。
                                        </li>
                                        <li>
                                            当社の承諾を受けることなくレンタカーについて損害保険に加入すること。
                                        </li>
                                        <li>
                                            レンタカーを日本国外に持ち出すこと
                                        </li>
                                        <li>
                                            その他第8条の借受条件又は貸渡条件に違反する行為をすること。
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                            <h3 class="terms__chapter--paragraph-title">第18条 (違法駐車)</h3>
                            <ol class="terms__chapter--paragraph-content">
                                <li>
                                    借受人又は運転者は、使用中にレンタカーに関し道路交通法に定める達法駐車をしたときは、
                                    借受人又は運転者は自ら違法駐車に係る反則金等を納付し、及び違法駐 車に伴うレッカー移動、保管、引取りなどの諸費用を負担するものとします。
                                </li>
                                <li>
                                    当社は、警察からレンタカーの放置駐車違反の連絡を受けたときは、借受人又は運転者に連絡し、速やかにレンタカーを移動させ、レンタカーの借受期間満了時又は当社の指示する時までに取扱い響察署に出頭して違反を処理するよう指示するものとし、借受人又は運転者はこれに従うものとします。
                                    なお、当社は、レンタカーが警察により移動された場合には、当社の判断により、自らレンタカーを管察から引き取る場合があります。
                                </li>
                                <li>
                                    前項の場合、当社は、借受人又は運転者に対し、違反処理の状況を交通反則告知書又は納付書、領収書等により確認するものとします。確認できない場合には、放置駐車違反をした事実及び警察署等に出頭し、違反者として法律上の措置に従うことを自認する旨の当社所定の文書(以下「自認書」といいます。)に自ら署名するよう求めることができ、借受人又は運転者はこれに従うものとし、また当社が定める駐車違反違約金を当社に対し速やかに支払うことに同意します。
                                    証等の資料を提出することができるものとし、借受人又は運転者はこれに同意するものとします。
                                </li>
                                <li>
                                    借受人又は運転者が所定の期間内に駐車違反に係る反則金又は諸費用を納付せず、
                                    当社が当該駐車違反に係る放置違反金又は諸費用(借受人又は運転者の探索やレンタカーの引き取りに要した費用を含むが、これに限られない)を負担したときは、
                                    借受人は当社に対し当社が負担した一切の費用を賠償するものとします。なお、借受人又は運転者が、第3項に基づき駐車違反違約金を当社に支払っている場合は、
                                    その額を限度として、放置違反金又は諸費用の賠償義務を免れるものとします。
                                </li>
                                <li>
                                    借受人又は運転者が、第3項に基づき駐車違反達約金を当社に支払った後、借受人又は運転者が罰金又は反則金を納付し、
                                    当社にその納付書、領収書等を提示した場合、又は当社が放置違反金の還付を現実に受けたときは、
                                    当社はすみやかに受取った駐車違反違約金相当額から返金に要する費用を差引いた金額を借受人又は運転者に返還します。
                                </li>
                                <li>
                                    当社が第5項の放置違反金納付命令を受けたとき、又は借受人若しくは運転者が当社が指定する期日までに第5項の請求額を支払わないときは、当社は借受人若しくは運転者の氏名、住所、
                                    運転免許証番号等を一般社団法人全国レンタカー協会情報管理システム(以下「全レ協システム」といいます。)に登録する等の措置をとるものとします。
                                </li>
                                <li>
                                    第7項の規定により、全レ協システムに登録された場合において、
                                    反則金が納付されたことにより放置違反金納付命令が取り消され(、又は第5項の規定による当社の請求額が全額当社に支払われ)たときは、当社は全レ協システムに登録したデータを削除するものとします。
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div class="terms__chapter">
                        <h2 class="terms__chapter--title">第5章 返還</h2>
                        <div class="terms__chapter--paragraph">
                            <h3 class="terms__chapter--paragraph-title">第19条 (借受人の返還責任)</h3>
                            <ol class="terms__chapter--paragraph-content">
                                <li>
                                    借受人または運転者は、レンタカー及び備品を借受期間満了時までに所定の返所において当社に返還するものとします。
                                </li>
                                <li>
                                    借受人または運転者が前項に違反したときは、当社に与えた一切の損害を賠償するものとします。
                                </li>
                                <li>
                                    借受人は、天災その他の不可抗力により借受期間内にレンタカーを返還することができないときは、直ちに当社に連絡し、当社の指示に従うものとします。
                                </li>
                            </ol>
                            <h3 class="terms__chapter--paragraph-title">第20条 (レンタカーの確認等)</h3>
                            <ol class="terms__chapter--paragraph-content">
                                <li>
                                    借受人は、当社立会いのもとに、レンタカーを通常の使用による劣化・摩耗を除き、引渡時の状態で返還 するものとします。
                                </li>
                                <li>
                                    借受人は、レンタカーの返還にあたって、レンタカー内に借受人、運転者又は同乗者の遺留品がないことを確認して返還するものとし、当社は、レンタカーの返還後の遺留品について保管の責を負わないものとします。
                                </li>
                            </ol>
                            <h3 class="terms__chapter--paragraph-title">第21条 (レンタカーの返還時期等)</h3>
                            <ol class="terms__chapter--paragraph-content">
                                <li>
                                    借受人は、第12条により借受期間を延長したときは、変更後の借受期間に対応する貸渡料金、又は変更前の貸渡料金と超過料金を合計した料金のうち、いずれか低い方の料金を支払うものとします。
                                </li>
                                <li>
                                    借受人は、第12条による当社の承諾を受けることなく借受期間を超過した後に返還したときは、前項の料金に加え、超過した時間に応じた超過料金の倍額の違約料を支払うものとします。
                                </li>
                            </ol>
                            <h3 class="terms__chapter--paragraph-title">第22条 (レンタカーの返還場所等)</h3>
                            <ol class="terms__chapter--paragraph-content">
                                <li>
                                    借受人は、第12条により所定の返還場所を変更したときは、返還場所の変更によって必要となる回送のための費用(以下「回送費用」という)を負担するものとします。
                                </li>
                                <li>
                                    借受人は、第12条による当社の承諾を受けることなく所定の返還場所以外の場所にレンタカーを返選したときは、回送費用の倍額の違約料を支払うものとします。
                                </li>
                            </ol>
                            <h3 class="terms__chapter--paragraph-title">第23条 (精算)</h3>
                            <ol class="terms__chapter--paragraph-content">
                                <li>
                                    借受人又は運転者は、レンタカー返還時に延長料金、回送費用、返還場所変更違約料等の未精算金(以下「未精算金」といいます。)がある場合には、当該未精算金を直ちに当社に支払うものとします。
                                </li>
                                <li>
                                    レンタカー返選時にガソリン、軽油等の燃料が未補充の場合、借受人又は運転者は、使用中の走行距離に応じて当社所定の換算表により算出した金額を、直ちに当社に支払うものとします。
                                </li>
                            </ol>
                            <h3 class="terms__chapter--paragraph-title">第24条 (レンタカーが返されなかった場合の措置)</h3>
                            <ol class="terms__chapter--paragraph-content">
                                <li>
                                    当社は、借受人に次の各号のいずれかが該当するときは、刑事告訴を行うなどの法的手続きのほか、
                                    車両位置情報システムを利用しレンタカーの所在を確認するのに必要な措置を実施するとともに一般社団法人全国レンタカー協会への不返還被害報告をする等の措置をとるものとします。
                                    <ol>
                                        <li>
                                            借受期間が満了したにもかかわらず当社の返還請求に応じないとき。
                                        </li>
                                        <li>
                                            借受人の所在が不明である等不返還と認められるとき。
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    前項各号の場合、借受人は、当社が借受人の探索及びレンタカーの回収に要した費用等を当社に支払うものとします。
                                </li>
                            </ol>
                            <h3 class="terms__chapter--paragraph-title">第25条 (貸渡情報の登録と利用の合意)</h3>
                            <ol class="terms__chapter--paragraph-content">
                                <li>
                                    借受人又は運転者は、第18条第7項又は第24条第1項のいずれかに該当することとなった場合は、
                                    当該事実及び借受人又は運転者の氏名、住所等を含む情報が、全レ協システムに7年を超えない期間登録されること並びにその情報が一般社団法人全国レンタカー協会及びこれに加盟する各地区レンタカー協会並びにこれらの会員であるレンタカー事業者によって貸渡契約締結の際の審査のために利用されることに同意するものとします。
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div class="terms__chapter">
                        <h2 class="terms__chapter--title">第6章 故障・事故・盗難時の措置</h2>
                        <div class="terms__chapter--paragraph">
                            <h3 class="terms__chapter--paragraph-title">第26条 (レンタカーの故障)</h3>
                            <p class="terms__chapter--paragraph-content">
                                借受人又は運転者は、使用中にレンタカーの異常又は故障を発見したときは、 直ちに運転を中止し、当社に連絡するとともに、当社の指示に従うものとします。
                            </p>
                            <h3 class="terms__chapter--paragraph-title">第27条 (事故)</h3>
                            <ol class="terms__chapter--paragraph-content">
                                <li>
                                    借受人又は運転者は、使用中にレンタカーに係る事故が発生したときは、直ちに運転を中止し、事故の大小にかかわらず法令上の措置をとるとともに、次に定める措置をとるものとします。
                                    <ol>
                                        <li>
                                            直ちに事故の状況等を当社に報告し、当社の指示に従うこと。
                                        </li>
                                        <li>
                                            前号の指示に基づきレンタカーの修理を行う場合は、当社が認めた場合を除き、当社又は当社の指定する工場で行うこと。 
                                        </li>
                                        <li>
                                            事故に関し当社及び当社が契約している保険会社の調査に協力し、当社及び保険会社が要求する書類等をなく提出すること。
                                        </li>
                                        <li>
                                            事故に関し相手方と示談その他の合意をするときは、予め当社の承諾を受けること。
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    借受人又は運転者は、前項のほか自らの責任において事故の処理・解決をするものとします。
                                </li>
                                <li>
                                    当社は、借受人又は運転者のため事故の処理について助言を行うとともに、その解決に協力するものとします。
                                </li>
                            </ol>
                            <h3 class="terms__chapter--paragraph-title">第28条 (盗難)</h3>
                            <p class="terms__chapter--paragraph-content">
                                借受人又は運転者は、使用中にレンタカーの盗難が発生したときその他被害を受けたときは、次に定める措置をとるものとします。
                            </p>
                            <ol class="terms__chapter--paragraph-content">
                                <ol>
                                    <li>
                                        直ちに最寄の警察に通報すること。
                                    </li>
                                    <li>
                                        直ちに被害状況等を当社に報告し、当社の指示に従うこと。 
                                    </li>
                                    <li>
                                        盗難・被害に関し当社及び当社が契約している保険会社の調査に協力し、当社及び保険会社が要求する書類等を遅延なく提出すること。
                                    </li>
                                </ol>
                            </ol>
                            <h3 class="terms__chapter--paragraph-title">第29条 (使用不能による貸渡契約の終了)</h3>
                            <ol class="terms__chapter--paragraph-content">
                                <li>
                                    借受期間中において故障・事故・盗難その他の事由(以下「故障等」という)によりレンタカーが使用できなくなったときは、貸渡契約は終了するものとします。
                                </li>
                                <li>
                                    借受人又は運転者は、前項の場合、 レンタカーの引取及び修理等に要する費用を負担するものとし、
                                    当社は受領済みの貸渡料金を返還しないものとします。但し、故障等が第3項又は第5項に定める事由による場合はこの限りでないものとします。
                                </li>
                                <li>
                                    故障等が貸渡前に存した瑕疵による場合は、借受人は当社から代替レンタカーの提供を受けることができるものとします。なお、代替レンタカーの提供条件については、第5条を準用するものとします。
                                </li>
                                <li>
                                    借受人が前項の代替レンタカーの提供を受けないときは、当社は受領済の貸料金を全額返還するものとします。なお、当社が代替レンタカーを提供できないときも同様とします。
                                </li>
                                <li>
                                    故障等が借受人、運転者及び当社のいずれの責にも掃すべからざる事由により生じた場合は、当社は、受領済みの賃料金から、貸渡から貸渡契約の終了までの期間に対応する貸渡料金を差し引いた残額を借受人に返還するものとします。
                                </li>
                                <li>
                                    借受人及び運転者は、本条に定める措置を除き、レンタカーを使用できなかったことにより生ずる損害について当社に対し、本条に定める以外のいかなる請求もできないものとします。
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div class="terms__chapter">
                        <h2 class="terms__chapter--title">第7章 賠償及び補償</h2>
                        <div class="terms__chapter--paragraph">
                            <h3 class="terms__chapter--paragraph-title">第30条 (借受人による賠償及び営業補償)</h3>
                            <ol class="terms__chapter--paragraph-content">
                                <li>
                                    借受人又は運転者は、借受人又は運転者が借り受けたレンタカーの使用中に第三者又は当社に損害を与えたときは、その損害を賠償するものとします。但し、当社の責に帰すべき事由による場合を除きます。
                                </li>
                                <li>
                                    前項の当社の損害のうち、事故、盗難、借受人又は運転者の責に帰すべき事由による故障、
                                    レンタカーの汚損・臭気等により当社がそのレンタカーを利用できないことによる損害についても、借受人はこれを賠償するものとします。
                                </li>
                                <li>
                                    前各項にかかわらず、激甚災害に対処するための特別の財政措置等に関する法律(昭和37年法律第150号)第2条に基づき数を災害と指定された災害(以下「激甚災害」という)による損害については、
                                    その損害が当該激甚災害に指定された地域において不可抗力により滅失し、き損し、
                                    又はその他の被害を受けたレンタカーに係るものである場合には、借受人又は運転者は、その損害を賠償することを要しないものとします。
                                </li>
                            </ol>
                            <h3 class="terms__chapter--paragraph-title">第31条 (保険)</h3>
                            <ol class="terms__chapter--paragraph-content">
                                <li>
                                    借受人又は運転者が約款及び細則に基づく賠償責任を負うときは、
                                    当社がレンタカーについて締結した損害保険契約により、次の限度内の保険金が給付されます。
                                    但し、その保険約款の免責事由に該当するときはこの保険金は給付されません。
                                    <ol>
                                        <li>対人補償1名につき無制限(自賠責保険を含む)</li>
                                        <li>対物補償1事故につき無制限(資額5万円)</li>
                                        <li>車両補償 1事故につき時価まで(免責額5万円 但し、バス・大型貨物車 10 万円)</li>
                                        <li>人身傷害補償1名につき2,000万円まで</li>
                                    </ol>
                                </li>
                                <li>
                                    保険金が給付されない損害及び前項の定めにより給付される保険金額を超える損害については、借受人又は運転者の負担とします。
                                </li>
                                <li>
                                    当社が前項に定める借受人又は運転者の負担すべき損害金を支払ったときは、借受人又は運転者は、直ちに当社の支払額を当社に弁済するものとします。
                                </li>
                                <li>
                                    第1項に定める保険金の免責額に相当する損害については、借受人が予め当社に免責補償料を支払ったときは当社の負担とします。但し、その免責補償料の支払いがないときは借受人又は運転者の負担とします。
                                </li>
                                <li>
                                    第1項に定める損害保険契約の保険料相当額は貸渡料金に含みます。
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div class="terms__chapter">
                        <h2 class="terms__chapter--title">第8章 解除</h2>
                        <div class="terms__chapter--paragraph">
                            <h3 class="terms__chapter--paragraph-title">第32条 (貸渡契約の解除)</h3>
                            <p class="terms__chapter--paragraph-content">
                                当社は、借受人又は運転者が借受期間 中に約款及び細則に違反したときは、何らの通知・催告を要せず貸渡契約を解除し、直ちにレンタカーの返還を請求することができるものとします。
                                この場合、当社は受領済の貸渡料金を借受人に返還しないものとします。
                            </p>
                            <h3 class="terms__chapter--paragraph-title">第33条 (同意解約)</h3>
                            <ol class="terms__chapter--paragraph-content">
                                <li>
                                    借受人は、借受期間中であっても、当社の同意を得て貸渡契約を解約することができるものとします。
                                    この場合、当社は、受領済の貸渡料金から、貸渡から返還までの期間に対応する貸渡料金を差し引いた残額を借受人に返還するものとします。
                                </li>
                                <li>
                                    借受人は、前項の解約を するときは、次の解約手数料を当社に支払うものとします。解約手数料={(予定借受期間に対応する基本料金) ー (貸渡から返還までの期間に対応する基本料金)}x50%
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div class="terms__chapter">
                        <h2 class="terms__chapter--title">第9章 個人情報</h2>
                        <div class="terms__chapter--paragraph">
                            <h3 class="terms__chapter--paragraph-title">第34条 (個人情報の利用目的)</h3>
                            <ol class="terms__chapter--paragraph-content">
                               <li>
                                    当社が借受人又は運転者の個人情報を取得し、利用する目的は次のとおりです。
                                    <ol>
                                        <li>
                                            レンタカー事業の許可を受けた事業者として、貸渡契約締結時に貸渡証を作成する等、事業許可の条件として義務付けられている事項を遂行するため。
                                        </li>
                                        <li>
                                            借受人又は運転者に、レンタカー及びこれらに関連したサービスの提供をするため。
                                        </li>
                                        <li>
                                            借受人又は運転者の本人確認及び審査をするため。
                                        </li>
                                        <li>
                                            レンタカー、中古車、その他の当社において取り扱う商品及びサービス等の提供、並びに各種イベント、キャンペーン等の開催について、宜伝印刷物の送付、メールの送信等の方法により、借受人又は運転者に案内するため。
                                        </li>
                                        <li>
                                            当社の取り扱う商品及びサービスの企画開発や顧客満足度向上策の検計を目的として、借受人又は運転者にアンケート調査を実施するため。
                                        </li>
                                        <li>
                                            個人情報を統計的に集計、分析し、個人を識別、特定できない形態に加工した統計データを作成するため。
                                        </li>
                                        <li>
                                            上記の利用目的のために会員登録するため。
                                        </li>
                                    </ol>
                               </li>
                               <li>
                                    第1項各号に定めていない目的で借受人又は運転者の個人情報を取得する場合には、あらかじめその利用目的を明示して行います。
                               </li>
                            </ol>
                        </div>
                    </div>
                    <div class="terms__chapter">
                        <h2 class="terms__chapter--title">第10章 雑則</h2>
                        <div class="terms__chapter--paragraph">
                            <h3 class="terms__chapter--paragraph-title">第35条 (相殺)</h3>
                            <p class="terms__chapter--paragraph-content">
                                当社は、約款及び細則に基づき借受人に金銭債務を負担するときは、借受人が当社に負担する金銭債務といつでも相殺することができるものとします。
                            </p>
                            <h3 class="terms__chapter--paragraph-title">第36条 (消費税)</h3>
                            <p class="terms__chapter--paragraph-content">
                                借受人は、約款及び細則に基づく取引に課せられる消費税(地方消費税を含む)を当社に対して支払うものとします。
                            </p>
                            <h3 class="terms__chapter--paragraph-title">第37条 (遅延損害金)</h3>
                            <p class="terms__chapter--paragraph-content">
                                借受人又は運転者及び当社は、約款及び細則に基づく金銭債務の履行を怠ったときは、相手方に対し年率14.6%の割合による遅延損害金を支払うものとします。
                            </p>
                            <h3 class="terms__chapter--paragraph-title">第38条 (代理貸渡し)</h3>
                            <p class="terms__chapter--paragraph-content">
                                この約款は、当社がレンタカーの保有者として、他の事業者に委託してレンタカーの貸渡しを代理させる取引を行い借受人ヘレンタカーを貸渡すときにおいても、適用されるものとします。
                            </p>
                            <h3 class="terms__chapter--paragraph-title">第39条 (準拠法等)</h3>
                            <ol class="terms__chapter--paragraph-content">
                                <li>
                                    準拠法は、日本法とします。
                                </li>
                                <li>
                                    邦文約款と、英文その他邦文以外の約款に齟齬があるときは、邦文約款を優先するものとします。
                                </li>
                            </ol>
                            <h3 class="terms__chapter--paragraph-title">第40条 (約款及び細則)</h3>
                            <ol class="terms__chapter--paragraph-content">
                                <li>
                                    当社は、予告なく約款及び細則を改訂し、又は約款の細則を別に定めることができるものとします。
                                </li>
                                <li>
                                    当社は、約款及び細則を改訂し又は別に細則を定めたときは、当社の営業店舗に掲示するとともに、当社の発行するパンフレット、料金表及びホームページ上にこれを記載するものとします。これを変更した場合も同様とします。
                                </li>
                            </ol>
                            <h3 class="terms__chapter--paragraph-title">第41条 (管轄裁判所)</h3>
                            <p class="terms__chapter--paragraph-content">
                                この約款及び細則に基づく権利及び義務について紛争が生じたときは、当社の本店所在地を管轄する裁判所をもって専属的合意管轄裁判所とします。
                            </p>
                        </div>
                    </div>
                    <p class="note">
                        附則 約款は、令和 3 年 3 月 15 日から施行します。
                    </p>
                </div>
            </section>
            <ScrollTop />
        </main>
        <Footer></Footer>
    </div>
</template>
<script>
import Header from "/src/components/common/Header";
import ScrollTop from 'primevue/scrolltop';
import Footer from "/src/components/common/Footer";
export default {
  name: 'Terms',
  components: {
    Header,
    ScrollTop,
    Footer
  }
}
</script>
<style lang="scss" scoped>
.home {
    text-align: left;
    font-size: 1rem;
    color: var(--color-black);
    font-family: var(--font-noto-sans);
    font-optical-sizing: var(--font-default-optical-sizing);
    font-style: var(--font-default-style);
}
section {
    margin: 8rem 2.4rem;
    &:first-child {
        margin: 4rem 2.4rem 8rem;
    }
}
.section {
    &__terms {
        text-align: center;
        font-size: var(--font-size-base);
        &--title {
            text-align: center;
            margin: 2.3rem 0;
            border-bottom: 2px solid var(--color-steelblue);
      
            h1 {
              color: var(--color-steelblue);
              letter-spacing: 0.15em;
              font-size: 2rem;
              font-family: var(--font-noto-sans);
              font-optical-sizing: var(--font-default-optical-sizing);
              font-style: var(--font-default-style);
              
              margin: unset;
            }
      
            h3 {
              color: var(--color-steelblue);
              letter-spacing: 0.15em;
              font-size: 1rem;
              font-family: var(--font-noto-sans);
              font-optical-sizing: var(--font-default-optical-sizing);
              font-style: var(--font-default-style);
              margin: unset;
              margin-bottom: 0.8rem;
            }
        }
        &--content {
            margin: 3rem auto 0;
            width: 75%;
            p.note {
                color: var(--color-black);
                font-size: 1rem;
                font-weight: bold;
                text-align: left;
                margin-top: 5rem;
            }
        }
    }
}
.terms__chapter {
    text-align: left;
    &:not(:first-child) {
        margin-top: 3rem;
    }
    &--title {
        max-height: 10rem;
        border-left: 0.3rem solid var(--color-skyblue);
        padding: 0 0.5rem;
    }
    &--paragraph {
        color: var(--color-black);
        &-content {
            font-size: 1rem;
        }
    }
}

@media screen and (max-width: 390px) {
    .section {

        &--title {
            h3 {
                font-size: .8rem;
            }
        }

        &__terms {
            &--content {
                margin: initial;
                width: initial;
            }
        }
    }
}
</style>